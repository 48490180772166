<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import CustomerLayout from "@/Layouts/CustomerLayout.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import { reloadWithFlashMessage } from '@/Components/Feedback.vue'
import {ref} from "vue";

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    canResetEmail: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
        onSuccess: () => {
            reloadWithFlashMessage('success', 'You\'ve successfully logged in')
        }
    });
};

let step = ref(0)

const updateStep = () => {
    if (form.email.length > 0) {
        step.value += 1;
    }
}
</script>

<template>
    <CustomerLayout title="Log In">
        <Head title="Log In"/>
        <form @submit.prevent="submit">
            <v-row>
                <v-col cols="12">
                    <h1>Please Log In to Access the Administrator Settings</h1>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="8" xs="4" v-if="step === 0">
                    <VTextField
                        id="email"
                        type="email"
                        label="Email"
                        v-model="form.email"
                        required
                        autofocus
                        autocomplete="username"
                    />
                    <InputError class="mt-2" :message="form.errors.email" />
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="8" xs="4" v-else-if="step === 1">
                    <VTextField
                        id="password"
                        type="password"
                        label="Password"
                        v-model="form.password"
                        required
                        autocomplete="current-password"
                    />
                    <InputError class="mt-2" :message="form.errors.password" />
                </v-col>
<!--                <v-col cols="12" v-if="step === 0">
                    <label class="flex items-center">
                        <v-checkbox label="Remember me" v-model:checked="form.remember" />
                    </label>
                </v-col>-->
            </v-row>
            <v-row>
                <v-col cols="12" v-if="step === 0">
                    <SecondaryButton type="submit" :disabled="form.processing" v-if="canResetPassword && step === 0" :href="route('password.request')" prepend-icon="fas fa-pencil" text="Forgot your email?" :error-messages="form.errors.email" class="mr-3"/>
                </v-col>
                <v-col cols="6" lg="2" md="2" sm="2" xs="2" v-if="step === 0">
                    <SecondaryButton type="submit" :disabled="form.processing" prepend-icon="fas fa-arrow-right" :href="route('register')" text="Create Account" class="mr-3" :error-messages="form.errors.email"/>
                </v-col>
                <v-col cols="6" lg="6" md="6" sm="2" xs="1" v-if="step === 0" class="d-flex justify-end-mobile">
                    <PrimaryButton @click="updateStep()" type="submit" :disabled="form.processing" prepend-icon="fas fa-arrow-right" text="Next Step" :error-messages="form.errors.email"/>
                </v-col>
                <v-col cols="8" lg="2" md="6" sm="2" xs="2" v-if="canResetEmail && step === 1">
                    <SecondaryButton type="submit" :disabled="form.processing" :href="route('password.email')" prepend-icon="fas fa-pencil" text="Forgot your password?" :error-messages="form.errors.email"/>
                </v-col>
                <v-col cols="4" lg="1" md="6" sm="2" xs="2" v-if="step === 1" class="d-flex justify-end">
                    <PrimaryButton @click="submit()" :disabled="form.processing" prepend-icon="fas fa-arrow-right" text="Log In" :error-messages="form.errors.email"/>
                </v-col>
            </v-row>
        </form>
    </CustomerLayout>
</template>

<style scoped>

</style>
